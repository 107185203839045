import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setAddCanalActions, setDeleteLoading, setEditChannelConfData, setEditChannelData, setSubmit} from "../store/canalActions";
import {Form, message} from "antd";
import {useAppSelector} from "../../../redux/hooks";
import {useTranslation} from "react-i18next";
import { GraphQLFetchData } from "./graphQLFetchData";
import { useNavigate } from 'react-router-dom';
import { Helpers } from "../../../utils/helpers";
const Hooks = () => {
    const {t} = useTranslation();
    const {GetListChannels,deleteChannel,getUrlConfExport,createChannelConfig,GetListServers,getChannelConfig, createChannel, getChannelById,updateChannel, UpdateChannelConfig,ChannelMultiDelete} = GraphQLFetchData()
    const [visible , setVisible] = useState<any>(false)
    const [visibleDeletePopup , setVisibleDeletePopup] = useState<any>(false)
    const [visibleExport , setVisibleExport] = useState<any>(false)
    const [statusSuccessMessages , setStatusSuccessMessages]=useState<any>(true)
    const [statusErrorMessages , setStatusErrorMessages] = useState<any>(true)
    const dispatch = useDispatch()
    const {acces_send} = Helpers()
    const [formAddCanal]=Form.useForm()
    const editchannelConfigData = useAppSelector((state) => state.CanalReducer.editchannelConfData)
    const editDataForm = useAppSelector((state) => state.CanalReducer.editDataForm)
    const values = useAppSelector((state) => state.CanalReducer.addCanalConstraintsData)
    const navigate = useNavigate();
    const loading=useAppSelector((state) => state.CanalReducer.loading)

    useEffect(()=>{
        formAddCanal.setFieldsValue({
            Title : values.title,
            Language:values.language,
            ServerIDs:values.ServerIDs,
            Quota:values.quota,
            EmbedVideo:values.EmbedVideo,
            Upload_Path:values.Upload_Path,
            PermalienVideo:values.PermalienVideo,
            ChannelConfigIdID:values.ChannelConfigIdID,
            Description:values.description,
            Upload_Url:values.Upload_Url,
            is_vbr:values.is_vbr,
            DefaultBitrateComputer:"",
            DefaultBitrateTablet:"",
            defaultBitrateMobile:"",
            ReadingProtocol:"",
            IsCdn:values.IsCdn,
            ShowMbr:values.ShowMbr
        })
    },[])
    const getListChannels = async(data:object) => {
        await GetListChannels(data)
    }
    const getChannelsConfig = async() => {        
        await getChannelConfig()
    }
    const getUrlExport = async(Id : any) => {
        await getUrlConfExport({variables:{input:{channelId:Id}}})
    }
    const DeleteChannel = async(ID : any) => {
        handleCancelDeletePopup();
        dispatch(setDeleteLoading(true))        
        await deleteChannel({variables:{id:ID}});
        dispatch(setDeleteLoading(false))        
    }

    const channelsMultiDelete = async(Id:any) => {
        handleCancelDeletePopup();
        dispatch(setDeleteLoading(true))        
        await ChannelMultiDelete({variables:{id:Id}});
        dispatch(setDeleteLoading(false))        
    }
    const updateChannelConf = async() => {
        await UpdateChannelConfig({variables:{input:{...editchannelConfigData}}})
    }
   const updateChannelForm = async () => {
    const WebtvChannelArr = editDataForm.Servers.map((el:any)=>el.Id)
    const ServersIds = editDataForm.ServersIds
    const AddServerIDs: number[] = []
    const RemoveServerIDs: number[] = []
    ServersIds?.forEach((id:number)=> {
        if(WebtvChannelArr.includes(id))return
        else if (!WebtvChannelArr.includes(id)){
            AddServerIDs.push(id)
        }
    });
    WebtvChannelArr?.forEach((id:number)=> {
        if(ServersIds.includes(id))return
        else if (!ServersIds.includes(id)){
            RemoveServerIDs.push(id)
        }
    });
        const {Id,Title,Description,Language,Quota,SendPushNotification,
            PermalienVideo,EmbedVideo,is_vbr,Upload_Path,Upload_Url,ChannelConfigIdID} = editDataForm
    await updateChannel({variables:{input:{
        Id,Title,Description,Language,Quota,SendPushNotification,PermalienVideo,AddServerIDs,
        EmbedVideo,is_vbr,Upload_Path,Upload_Url,ChannelConfigIdID,RemoveServerIDs,Uid:""}}})
        onResetCanal()
   }

    const getServers = async() => {
        await GetListServers()
    }
   
    const addChannel = async(values : any) => {
        await createChannel({variables:{input:{...values,Uid:""}}})
    }


    const handleShowModal = () => {
         setVisible(true)
    }
    const handleShowModalDeletePopup = () => {
        setVisibleDeletePopup(true)
   }
    const handleCancel = () => {
        setVisible(false)
    }
    const handleCancelDeletePopup = () => {
        setVisibleDeletePopup(false)
    }
    const handleShowModalExport = (Id: any) => {
        setVisibleExport(true)
        getUrlExport(Id)
    }
    const handleCancelModalExport = () => {
        setVisibleExport(false)
    }
    const CopyUrlAuditeur = async () => {
        if ((document.getElementById("myUrlAuditeurCanal" ) as HTMLFormElement).value === "") {
            await setStatusErrorMessages(false)
            return (
                statusErrorMessages && message.error({content: t('classement.Thisfieldisempty'), duration: 2}).then(
                   
                    async () => {
                        setStatusErrorMessages(true)
                    }
                )
            )
        } else {

            (document.getElementById("myUrlAuditeurCanal") as HTMLFormElement).select();
            document.execCommand("Copy");
            await setStatusSuccessMessages(false)
            statusSuccessMessages && await message.success({content: t('classement.thisfieldissuccessfullycopied'), duration: 2}).then(
                async () => {
                    setStatusSuccessMessages(true)
                }
            );
        }
    }

    const handleAddCanal = () => {
        acces_send('parameters_canal_post_add_action')
        navigate(`/classement/channel/id/addChannel`, {replace: false})
    }
 const getChannelInfoById = async (Id : number) => {
    await getChannelById({variables:{id:Id}})
    }
    
    const onResetCanal =async () => {
         dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "Title",
            addCanalConstraintsDataValueChange: ""
        }));
         dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "Description",
            addCanalConstraintsDataValueChange: ""
        }));
        dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "ShowMbr",
            addCanalConstraintsDataValueChange: ""
        }));
        dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "defaultBitrateMobile",
            addCanalConstraintsDataValueChange: ""
        }));
        
         dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "Language",
            addCanalConstraintsDataValueChange: ""
        }));
         dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "ServerIDs",
            addCanalConstraintsDataValueChange: []
        }));
        dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "ServersIds",
            addCanalConstraintsDataValueChange: []
        }));
         dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "Quota",
            addCanalConstraintsDataValueChange: 0
        }));
         dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "is_vbr",
            addCanalConstraintsDataValueChange: false
        }));
         dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "SendPushNotification",
            addCanalConstraintsDataValueChange: true
        }));
        dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "Upload_Path",
            addCanalConstraintsDataValueChange: ""
        }));
        dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "Upload_Url",
            addCanalConstraintsDataValueChange: ""
        }));
        dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "PermalienVideo",
            addCanalConstraintsDataValueChange: ""
        }));
        dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "EmbedVideo",
            addCanalConstraintsDataValueChange: ""
        }));
        dispatch(setAddCanalActions({
            addCanalConstraintsDataNameChange: "WebtvChannelConfig",
            addCanalConstraintsDataValueChange: {
                DefaultBitrateTablet:"",DefaultBitrateComputer:"",defaultBitrateMobile:"",
            }
        }));
        dispatch(setEditChannelConfData({
            Id:"",
            IsCdn:false,
            ReadingProtocol:"",
            DefaultBitrateMobile:"",
            DefaultBitrateTablet:"",
            DefaultBitrateComputer:"",
            ShowMbr:-1
        }))

    }

 const onFinishEDitCanal = async ()=>{
    await updateChannelConf();
    await updateChannelForm();
 }
    const onFinishAddCanal = async () => {
        const DefaultBitrateComputer= editchannelConfigData.DefaultBitrateComputer
        const DefaultBitrateTablet = editchannelConfigData.DefaultBitrateTablet
        const DefaultBitrateMobile = editchannelConfigData.DefaultBitrateMobile
        const IsCdn = editchannelConfigData.IsCdn
        const ReadingProtocol = editchannelConfigData.ReadingProtocol
        const ShowMbr = editchannelConfigData.ShowMbr
        await createChannelConfig({variables:{input:{DefaultBitrateComputer,DefaultBitrateTablet,DefaultBitrateMobile,IsCdn,ReadingProtocol,ShowMbr}}}).then((res : any)=>{ 
       let {Upload_Path,EmbedVideo,Quota,ServersIds,Language,Title,SendPushNotification,is_vbr,Upload_Url,Description,PermalienVideo} = editDataForm
        addChannel({Upload_Path,EmbedVideo,PermalienVideo,Quota,ServerIDs:ServersIds,Language,Title,SendPushNotification,is_vbr,Upload_Url,Description,ChannelConfigIdID:res?.data.createChannelConfig.Id})
         onResetCanal();
    }
        )
        .catch((e) =>  
           {           
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "errorMessage",
                addCanalConstraintsDataValueChange:[{error:"il y a un erreur s'est produit ",openSnack:true,errType:"error",autoHide:100,position:{horizontal:'center',vertical: 'top'}}]
            }))
            }
            );
        ;
    }
    const onValueFormUserchangeCanal =async (val:any) => {
        if(val[0]==="Title"){
            dispatch(setAddCanalActions({
                    addCanalConstraintsDataNameChange: "Title",
                    addCanalConstraintsDataValueChange: val[1]
           }))

        }
        if(val[0]==="DefaultBitrateMobile"){
            dispatch(setAddCanalActions({
                    addCanalConstraintsDataNameChange: "defaultBitrateMobile",
                    addCanalConstraintsDataValueChange: val[1]
           }))

        }
        if(val[0]==="MBR"){
            dispatch(setAddCanalActions({
                    addCanalConstraintsDataNameChange: "MBR",
                    addCanalConstraintsDataValueChange: val[1]
           }))

        }
        else if(val[0]==="Quota"){
            const inputValue = val[1]
            const numericValue = inputValue.replace(/\D/g, '');
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "Quota",
                addCanalConstraintsDataValueChange: numericValue
            }))
        
        }else if(val[0]==="Description"){
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "Description",
                addCanalConstraintsDataValueChange: val[1]
            }))

        }else if(val[0]==="Upload_Path"){
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "Upload_Path",
                addCanalConstraintsDataValueChange: val[1]
            }))

        }else if(val[0]==="Upload_Ur"){
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "Upload_Url",
                addCanalConstraintsDataValueChange: val[1]
            }))

        }else if(val[0]==="ReadingProtocol"){
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "ReadingProtocol",
                addCanalConstraintsDataValueChange: val[1]
            }))

        }else if(val[0]==="PermalienVideo"){
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "PermalienVideo",
                addCanalConstraintsDataValueChange: val[1]
            }))

        }else if(val[0]==="EmbedVideo"){
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "EmbedVideo",
                addCanalConstraintsDataValueChange: val[1]
            }))

        }else if(val[0]==="DefaultBitrateComputer"){
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "defaultBitrateComputer",
                addCanalConstraintsDataValueChange: val[1]
            }))

        }else if(val[0]==="DefaultBitrateTablet"){
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "defaultBitrateTablet",
                addCanalConstraintsDataValueChange: val[1]
            }))

        }else if(val[0]==="Langue"){
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "language",
                addCanalConstraintsDataValueChange: val[1]
            }))

        }else if(val[0]==="server_Stream"){
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "ServerIDs",
                addCanalConstraintsDataValueChange: val[1]
            }))

        }else if(val[0]==="CDN"){
            let bol
            if(val[1]==="Non"){bol=false}
            else {bol=true}
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "IsCdn",
                addCanalConstraintsDataValueChange: bol
            }))

        }else if(val[0]==="is_vbr"){
            let bol
            if(val[1]=="Non"){bol=false}
            else {bol=true}
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "is_vbr",
                addCanalConstraintsDataValueChange: bol
            }))
        }else if(val[0]==="SendPushNotification"){            
            let bol
            if(val[1]==="Non"){bol=false}
            else {bol=true}
             dispatch(setAddCanalActions({
                addCanalConstraintsDataNameChange: "SendPushNotification",
                addCanalConstraintsDataValueChange: bol
            }))
        }
    }

    const handleEditCanal = (record:any) =>{
        dispatch(setEditChannelData(record))
        navigate(`/classement/channel/${record?.Id}/editChannel`)
    }
    return({
        channelsMultiDelete,
        getUrlExport,
        onFinishEDitCanal,
        updateChannelConf,
        DeleteChannel,
        getServers,
        getChannelsConfig,
        getListChannels,
        handleAddCanal,
        onResetCanal,
        formAddCanal,
        handleShowModal,
        visible,
        handleCancel,
        handleShowModalExport,
        visibleExport,
        handleCancelModalExport,
        CopyUrlAuditeur,
        onFinishAddCanal,
        onValueFormUserchangeCanal,
        handleEditCanal,
        getChannelInfoById,
        values,
        visibleDeletePopup,
        handleCancelDeletePopup,
        handleShowModalDeletePopup,
        loading
    })
}
export default Hooks